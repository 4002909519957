import React, { useEffect, useState } from "react";
import "./AnimationSlider.css";
import { Link } from "react-router-dom";

const AnimationSlider = () => {
  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    const nextButton = document.getElementById("next");
    const prevButton = document.getElementById("prev");
    const carousel = document.querySelector(".carousel");
    const listHTML = document.querySelector(".carousel .list");
    const seeMoreButtons = document.querySelectorAll(".seeMore");
    const backButton = document.getElementById("back");
    let unAcceppClick;

    const showSlider = (type) => {
      nextButton.style.pointerEvents = "none";
      prevButton.style.pointerEvents = "none";

      carousel.classList.remove("next", "prev");
      let items = document.querySelectorAll(".carousel .list .item");
      if (type === "next") {
        listHTML.appendChild(items[0]);
        carousel.classList.add("next");
      } else {
        listHTML.prepend(items[items.length - 1]);
        carousel.classList.add("prev");
      }
      clearTimeout(unAcceppClick);
      unAcceppClick = setTimeout(() => {
        nextButton.style.pointerEvents = "auto";
        prevButton.style.pointerEvents = "auto";
      }, 2000);
    };

    nextButton.onclick = () => {
      showSlider("next");
    };

    prevButton.onclick = () => {
      showSlider("prev");
    };

    seeMoreButtons.forEach((button) => {
      button.onclick = () => {
        carousel.classList.remove("next", "prev");
        carousel.classList.add("showDetail");
      };
    });

    backButton.onclick = () => {
      carousel.classList.remove("showDetail");
    };

    return () => {
      if (nextButton) nextButton.onclick = null;
      if (prevButton) prevButton.onclick = null;
      seeMoreButtons.forEach((button) => {
        button.onclick = null;
      });
      if (backButton) backButton.onclick = null;
    };
  }, []);

  return (
    <div className="carousel">
      <div className="list">
        <div className="item">
          <Link to="/product/675c42c94cec212f5e7b8b23">
            <img src="anime/DANDADAN.png" alt="Airpod" />
          </Link>
          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">DANDADAN</div>
            <div className="des">
            Dandadan T-shirts are a perfect choice for fans of the quirky and action-packed manga series. Showcasing vibrant artwork of beloved characters like Okarun, Momo, and their wild supernatural encounters, these shirts capture the eccentric and thrilling spirit of the series.
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">DANDADAN</div>
            <div className="des">
            Crafted from premium materials, they combine comfort with lasting durability, making them great for everyday wear or as part of your anime-inspired wardrobe. Whether you prefer bold, dynamic designs or subtle nods to the paranormal chaos of Dandadan
            </div>
          </div>
        </div>

        <div className="item">
          <Link to="/product/6759bd8c4cec212f5e7b8440">
            <img src="anime/WOLVERINE.png" alt="Airpod" />
          </Link>
          <div className="introduce">
            <div className="header-title">MARVEL</div>
            <div className="topic">WOLVERINE</div>
            <div className="des">
            A Wolverine t-shirt features bold designs showcasing the iconic Marvel character, combining superhero style with comfort for fans.
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">WOLVERINE</div>
            <div className="des">
            A Wolverine and Deadpool t-shirt blends their iconic styles, showcasing Marvel’s dynamic duo with action-packed, edgy, and bold designs.
           
            </div>
          </div>
        </div>

        



        <div className="item">
          <Link to="/product/67185f830177e69101d73a76">
            <img src="anime/BATMAN_BEN_AFFLECK.png" alt="Airpod" />
          </Link>
          <div className="introduce">
            <div className="header-title">DC</div>
            <div className="topic">BATMAN</div>
            <div className="des">
            "Elevate Your Style with Our Unique Batman Anime T-Shirt: A Perfect Blend of Classic Heroism and Dynamic Art, Ideal for Fans Who Love to Stand Out and Embrace Their Inner Crusader!"
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">BATMAN</div>
            <div className="des">
            "Unleash your inner hero with this striking Batman anime T-shirt! Featuring a unique blend of classic Batman imagery and dynamic anime art, this shirt showcases Gotham’s dark knight in a bold, eye-catching style."
            </div>
          </div>
        </div>

        <div className="item">
          <Link to="/product/66afadc9d525a49424eb2fd4">
            <img
              src="anime/SPIDERMAN_OVERSIZED_SKY_BLUE_LIMITED.png"
              alt="Airpod"
            />
          </Link>

          <div className="introduce">
            <div className="header-title">MOVIE</div>
            <div className="topic">SPIDER MAN</div>
            <div className="des">
              "Unleash your inner hero with our exclusive Spider-Man anime
              T-shirt! Featuring a dynamic design that captures the essence of
              your favorite web-slinger, this shirt is perfect for fans who want
              to swing into action with style."
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">SPIDER MAN</div>
            <div className="des">
              Spider-Man t-shirts are a fantastic way to showcase your love for
              the iconic web-slinger. Featuring dynamic designs of Spider-Man in
              action, these shirts capture the essence of Marvel's beloved
              superhero. Made from soft, durable fabrics, they are ideal for
              everyday wear or special events. With a variety of styles and
              prints, from classic comic book art to modern movie renditions,
              there's a Spider-Man t-shirt for every fan.
            </div>
          </div>
        </div>

        <div className="item">
          <Link to="/product/671c70730177e69101d74c7c">
            <img src="anime/AIZEN_WHITE.png" alt="Airpod" />
          </Link>
          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">BLEACH</div>
            <div className="des">
            "Master of Deception, Aizen’s Ambition Unleashed. With power and elegance, he reigns over Soul Society. Trust no one; illusion is his art. Embrace the chaos, join the revolution!"
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">AIZEN</div>
            <div className="des">
            "Deception is an art, and Aizen is the artist. With every move, he redefines power and control. Join the revolution, challenge the norm, and discover what lies beneath the surface of Soul Society."
            </div>
          </div>
        </div>
        <div className="item">
          <Link to="/product/673f5dd94cec212f5e7afb1d">
            <img
              src="anime/UPPER MOON.png"
              alt="Airpod"
            />
          </Link>
          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">DEMON SLAYER</div>
            <div className="des">
            "Unleash your inner warrior with this Upper Moon 1 Demon Slayer T-Shirt, inspired by the legendary Kokushibo, the most powerful demon in the Upper Moons. This premium T-shirt is a must-have for every Demon Slayer fan, blending style and anime passion into one striking piece."
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">DEMON SLAYER</div>
            <div className="des">
            Upper Moon Demon Slayer T-shirts are perfect for fans of the darker and more powerful side of the popular anime series. Featuring stunning artwork of fearsome Upper Moon demons like Kokushibo, Doma, and Akaza, these shirts capture the show's intense and menacing aesthetic. Crafted from high-quality materials, they offer both comfort and durability for everyday wear.
            </div>
          </div>
        </div>

        <div className="item">
          <Link to="/product/679a2daba1564c5b37fb7764">
            <img src="anime/KAISEN_OVERSIZED_WHITE_LIMITED.png" alt="Airpod" />
          </Link>
          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">JUJUTSU KAISEN</div>
            <div className="des">
              "Immerse yourself in the supernatural battles of Jujutsu Kaisen
              with our exclusive anime T-shirt! Featuring striking designs of
              iconic characters like Yuji, Gojo, and Megumi, this shirt is a
              must-have for any fan of the series. Made from premium,
              comfortable fabric."
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">JUJUTSU KAISEN</div>
            <div className="des">
              Jujutsu Kaisen t-shirts are a must-have for fans of the thrilling
              anime series. Showcasing characters like Yuji Itadori, Satoru
              Gojo, and Megumi Fushiguro, these shirts bring the intense and
              dark world of Jujutsu Kaisen to life. Made from soft, high-quality
              fabric, they ensure comfort while displaying your fandom. With
              bold and intricate designs, these t-shirts capture the essence of
              the series' action-packed and supernatural elements.
            </div>
          </div>
        </div>

        <div className="item">
          <Link to="/product/673f5d104cec212f5e7af61c">
            <img
              src="anime/SAKAMATO DAYS.png"
              alt="Airpod"
            />
          </Link>
          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">SAKAMATO DAYS</div>
            <div className="des">
            Sakamoto Days T-shirts are the ultimate tribute to fans of the action-packed, comedic manga series. Featuring bold and dynamic artwork of Taro Sakamoto, Shin, and other beloved characters, these shirts perfectly capture the blend of thrilling action and heartfelt humor that defines the series.
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">SAKAMATO DAYS</div>
            <div className="des">
            Made from high-quality materials, they provide a comfortable fit and lasting durability, ideal for everyday wear. Whether you’re drawn to designs showcasing Sakamoto’s legendary assassin days or his hilarious life as a family man, these T-shirts let you wear your love for Sakamoto Days with style and pride!
            </div>
          </div>
        </div>

        <div className="item">
          <Link to="/product/673f546f4cec212f5e7aecf0">
            <img
              src="anime/SOLO LEVELING.png"
              alt="Airpod"
            />
          </Link>

          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">SOLO LEVELING</div>
            <div className="des">
              "Step into the world of Solo Leveling with our exclusive anime
              T-shirt! Featuring dynamic designs of Sung Jin-Woo and other key
              characters, this shirt is a must-have for any fan of the epic
              manhwa. Crafted from premium, comfortable fabric"
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">SOLO LEVELING</div>
            <div className="des">
              Solo Leveling t-shirts are a fantastic way for fans to show their
              love for the popular web novel and manhwa. Featuring striking
              designs of characters like Sung Jin-Woo, these shirts capture the
              intense and stylish essence of the series. Made from high-quality,
              comfortable materials.
            </div>
          </div>
        </div>
        <div className="item">
          <Link to="/product/66afadead525a49424eb3081 ">
            <img src="anime/NARUTO 4.png" alt="Airpod" />
          </Link>

          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">NARUTO</div>
            <div className="des">
              "Step into the world of ninjas, jutsus, and epic battles with our
              exclusive collection of Naruto anime T-shirts. Whether you're a
              loyal fan of Naruto Uzumaki, the determined Sasuke Uchiha, or the
              wise Kakashi Hatake, our shirts are designed to bring your
              favorite characters to life."
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">NARUTO</div>
            <div className="des">
              Embrace the spirit of Konoha with our dynamic range of Naruto
              anime T-shirts. Perfect for every ninja in training, our shirts
              feature bold designs that capture the essence of the Naruto
              series. Whether you’re repping the Hidden Leaf Village or showing
              off your love for the Akatsuki.
            </div>
          </div>
        </div>
        <div className="item">
          <Link to="/product/66afa8e1d525a49424eb25bd">
            <img
              src="anime/FORBIDDEN_FOUR_OVERSIZED_WHITE_LIMITED.png"
              alt="Airpod"
            />
          </Link>
          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">FORBIDDEN FOUR</div>
            <div className="des">
              "Dive into the dark and mysterious world of the "Forbidden Four"
              with our exclusive T-shirt. This design is perfect for fans who
              appreciate the edgier side of anime, featuring four legendary
              figures whose powers are both revered and feared."
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">FORBIDDEN FOUR</div>
            <div className="des">
              By wearing the "Forbidden Four" T-shirt, you're not just
              showcasing your love for anime—you're aligning yourself with a
              select group of fans who appreciate the depth, power, and mystery
              of these iconic characters. Whether you're at a convention, out
              with friends, or just enjoying some downtime, this T-shirt is your
              badge of honor in the world of anime.
            </div>
          </div>
        </div>
        <div className="item">
          <Link to="/product/673f5d784cec212f5e7af898">
            <img
              src="anime/MEGUNA.png"
              alt="Airpod"
            />
          </Link>
          <div className="introduce">
            <div className="header-title">ANIME</div>
            <div className="topic">JUTUTSU KAISEN</div>
            <div className="des">
            Sukuna T-shirts are a must-have for fans of Jujutsu Kaisen and the King of Curses himself. Featuring striking artwork of Sukuna's menacing expressions, intricate tattoos, and powerful aura, these shirts embody his fearsome presence.
            </div>
            <button
              className={showDetail ? "seeMore" : " seeMore show"}
              onClick={() => setShowDetail(true)}
            >
              SEE MORE &#8599;
            </button>
          </div>
          <div className="detail">
            <div className="header-title">JUTUTSU KAISEN</div>
            <div className="des">
            Made from high-quality materials, they provide both comfort and durability, perfect for casual wear or showcasing your love for the series. With designs ranging from minimalist curse marks to detailed illustrations of Sukuna's iconic moments, these T-shirts are ideal for any anime fan looking to channel their inner curse.
            </div>
          </div>
        </div>
      </div>
      <div className="arrows">
        <button id="prev">
          {" "}
          <i class="fa-solid fa-chevron-left"></i>
        </button>
        <button id="next">
          {" "}
          <i class="fa-solid fa-chevron-right"></i>{" "}
        </button>
        <button
          id="back"
          className={showDetail ? "" : "block"}
          onClick={() => setShowDetail(false)}
        >
          See All &#8599;
        </button>
      </div>
    </div>
  );
};

export default AnimationSlider;
