import React from "react";
import "./ShippingPolicy.css";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";
import { Helmet } from "react-helmet";

const ShippingPolicy = () => {
  return (
    <>
    <Helmet>
        <title>Shipping Policy | Kanime Store</title>
        <meta name="description" content="Learn about Kanime Store's shipping policy. We offer free shipping across India with delivery in 5 to 7 business days." />
        <meta name="keywords" content="shipping policy, free shipping, shipping fees, Kanime Store, delivery time" />
        <link rel="canonical" href="https://www.kanime.store/shipping-policy" />
      </Helmet>
      <ScrollToTop />
      <div className="total">
        <div className="box">
          <h1>SHIPPING POLICY</h1>
          <h3>DISPATCH</h3>
          <p>
            After you place your order, our products are sent out of our
            warehouse within 24 to 48 business hours.
          </p>
          <h3>TIME OF SHIPPING</h3>
          <p>We'll deliver your merchandise in 5 to 7 business days.</p>
          <h3>A DELAY IN SHIPPING</h3>
          <p>
            Any additional delays resulting from operational, internal, or
            regional logistics issues once the order ships from the warehouse
            will not be Kanimé's fault.
          </p>
          <h3>SHIPPING FEES</h3>
          <p>
            We offer free shipping on order above Rs.1000/- of our products throughout India. If
            the buyer returns the product to the warehouse without picking it
            up, there will be additional shipping costs.Order below Rs.1000 will be charged according to their Pinode.
          </p>
        </div>
      </div>
    </>
  );
};

export default ShippingPolicy;
